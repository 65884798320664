import React, {useEffect, useState} from "react";
import {hasCookie, setCookie} from "cookies-next/client";
import * as styled from "./CookieCompStyled";
import {NavigationURLs} from "../../functions/NavigationFunctions";
import SingleCheckboxAndText from "../checkboxComp/SingleCheckboxAndText";

const CookieConsent = () => {
    const [showConsent, setShowConsent] = React.useState(true);
    const [marketing, setMarketing] = useState<boolean>(false);
    const [necessary, setNecessary] = useState<boolean>(true);
    const [visibleCookie, setVisibleCookie] = useState<boolean>(true);

    useEffect(() => {
        setShowConsent(hasCookie("setShowConsent"));
    }, []);

    const acceptCookie = () => {
        setShowConsent(true);
        setMarketing(true);
        setNecessary(true);
        setCookie("setShowConsent", "true", { maxAge: 60 * 60 * 24 * 365 }); // 1 yıl
        setCookie("setMarketing", "true", { maxAge: 60 * 60 * 24 * 365 });
        setCookie("setNecessary", "true", { maxAge: 60 * 60 * 24 * 365 });

        // Facebook Pixel etiketini tetikle
        if (necessary) {
            // fbq('track', 'PixelEventName');
        }

        // GTM etiketini tetikle
        if (necessary) {
            // window.dataLayer.push({event: 'GTMEventName'});
        }
    };
    const handleReject = () => {
        // Çerezleri reddetme işlemleri burada yapılabilir.
        // Ayrıca kullanıcı tercihlerini sıfırlamak için state'leri de sıfırlayabiliriz.
        setMarketing(false);
        setNecessary(false);
        setCookie("setShowConsent", "false", { maxAge: 60 * 60 * 24 });
        setCookie("setMarketing", "false", { maxAge: 60 * 60 * 24 });
        setCookie("setNecessary", "false", { maxAge: 60 * 60 * 24 });
        setVisibleCookie(false);
        setShowConsent(false);
    };

    const handleSaveSettings = () => {
        // Kullanıcının tercihlerini kaydetme işlemleri burada yapılabilir.
        setCookie("setMarketing", marketing.toString(), { maxAge: 60 * 60 * 24 * 365 });
        setCookie("setNecessary", necessary.toString(), { maxAge: 60 * 60 * 24 * 365 });

        // Kullanıcının tercihlerini saklamak için başka işlemler yapılabilir
        setShowConsent(true);
        setVisibleCookie(false);
    };

    if (showConsent) {
        return null;
    }

    return (
        <React.Fragment>
            <styled.CookieCompWrapper className={visibleCookie ? 'active' : undefined}>
                <h3 className={'cookie-title'}>🍪 Çerez Kullanımı Hakkında Bilgilendirme</h3>
                <styled.CookieCompContent>
                    <div className={'cookie-content'}>
                        Sitemiz, çerezler aracılığıyla bilgisayarınıza veya mobil cihazınıza belirli bilgiler
                        kaydedebilir.
                        Bu çerezler, site performansını artırmak ve size daha iyi hizmet sunmak için kullanılır.Daha
                        fazla
                        bilgi için <a href={NavigationURLs.privacy} style={{color: '#D2147D'}}>Gizlilik
                        Politikamızı</a> inceleyebilirsiniz. Çerez kullanımını kabul ederek, çerezlerin
                        kullanımını onaylamış olursunuz. Eğer çerezleri reddetmek
                        isterseniz, bazı özelliklerimiz düzgün çalışmayabilir.Size özel bir deneyim almak
                        istemediğinizde,
                        <div className={'button-reject'} onClick={handleReject}>burada</div> Reddet'i tıklayarak
                        deneyimi reddedebilirsiniz.
                    </div>
                </styled.CookieCompContent>
                <styled.CookieCompButtons>
                    <styled.CookieCompButtonsWrapper>
                        <SingleCheckboxAndText titleColor cookie={true} checked={marketing}
                                               onChange={() => setMarketing(!marketing)}>
                            Pazarlama Çerezleri
                        </SingleCheckboxAndText>
                        <SingleCheckboxAndText titleColor cookie={true} checked={necessary} disabled={true}
                                               onChange={() => setNecessary(!necessary)}>
                            Zorunlu Çerezler
                        </SingleCheckboxAndText>
                    </styled.CookieCompButtonsWrapper>
                    <styled.CookieCompButtonsWrapper className={'mobile-direction-row'}>
                        <styled.CookieCompButton onClick={acceptCookie}>Kabul Et</styled.CookieCompButton>

                        <styled.CookieCompButton onClick={handleSaveSettings}>Ayarları Kaydet
                        </styled.CookieCompButton>
                    </styled.CookieCompButtonsWrapper>
                </styled.CookieCompButtons>
            </styled.CookieCompWrapper>
        </React.Fragment>

    );
};

export default CookieConsent;
