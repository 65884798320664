import React from "react";
import styled from "styled-components";
import {deviceMax} from "../../../styles/device";
import Colors from "../../constants/Colors";

type IProps = {
    children: React.ReactNode;
    titleColor?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    center?: boolean;
    backgroundCheckButtonColor?: boolean;
    className?: string;
    mobile?: boolean;
    cookie?: boolean;
    disabled?: boolean;
    dataTestId?: string;
}

const SingleCheckboxAndText = (
    {
        children,
        titleColor = true,
        checked = false,
        center = false,
        backgroundCheckButtonColor = false,
        onChange = () => {
        },
        className,
        mobile = false,
        cookie = false,
        disabled = false,
        dataTestId="",
    }: IProps
) => {


    return (
        <StyledOnlyCheckbox titleColor={titleColor} center={center} mobile={mobile}
                            backgroundCheckButtonColor={backgroundCheckButtonColor} className={className} cookie={cookie} data-testid={dataTestId}>
            <label>
                <input type="checkbox"
                       defaultChecked={checked}
                       onChange={onChange}
                       disabled={disabled}
                       title={'pembe panjur'}

                />
                <span className="checkmark"></span>
            </label>
            <div className="title">
                {children}
            </div>
        </StyledOnlyCheckbox>
    )
};

type IStyledProps = {
    titleColor?: boolean;
    center?: boolean;
    backgroundCheckButtonColor?: boolean;
    mobile?: boolean;
    cookie?: boolean;
}

const StyledOnlyCheckbox = styled.div`
    position: relative;
    display: ${(props: IStyledProps) => props.cookie ? 'flex' : 'block'};
    min-height: ${(props: IStyledProps) => props.cookie ? '29px' : props.mobile ? '34px' : 'auto'};
    margin-bottom: ${(props: IStyledProps) => props.cookie ? '0' : '10px'};
    margin-right: ${(props: IStyledProps) => props.cookie ? '20px' : '0'};

    @media ${deviceMax.tablet} {
        margin-right: 0;
        margin-bottom: ${(props: IStyledProps) => props.cookie ? '10px' : '0'};

        &:last-child {
            margin-bottom: 0;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.check-wrapper {
        display: table;
        margin-bottom: 10px;

        > .title {
            display: table-cell;
            vertical-align: middle;
        }
    }

    > label {
        position: relative;
        display: block;
        cursor: pointer;
        user-select: none;
        height: ${(props: IStyledProps) => props.cookie ? '26px' : 'auto'};

        input ~ .checkmark {
            background-color: ${Colors.white};

            &:after {
                display: none;
                border: solid ${Colors.checkboxCheckColor};
                border-width: 0 2px 2px 0;
            }

        }

        > input:checked ~ .checkmark {
            background-color: ${Colors.white};

            &:after {
                display: block;
                border: solid ${Colors.checkboxCheckColor};
                border-width: 0 2px 2px 0;
            }
        }

        > input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        > .checkmark {
            position: absolute;
            top: ${(props: IStyledProps) => props.center ? '-3px' : '3px'};
            left: 0;
            height: ${(props: IStyledProps) => props.mobile ? '17px' : (props.cookie ? '20px' : '22px')};
            width: ${(props: IStyledProps) => props.mobile ? '17px' : (props.cookie ? '20px' : '22px')};
            background-color: ${(props: IStyledProps) => props.backgroundCheckButtonColor ? Colors.white : Colors.checkboxUncheckColor};
            border-radius: 8px;

            @media ${deviceMax.tablet} {
                border-radius: 36px;
                height: ${(props: IStyledProps) => props.cookie ? '16px' : '29px'};
                width: ${(props: IStyledProps) => props.cookie ? '16px' : '29px'};
            }

            &:after {
                content: "";
                position: absolute;
                left: ${(props: IStyledProps) => props.mobile ? '6px' : (props.cookie ? '7px' : '8px')};
                top: ${(props: IStyledProps) => props.mobile ? '4px' : (props.cookie ? '4px' : '5px')};
                width: ${(props: IStyledProps) => props.mobile ? '3px' : '4px'};
                height: ${(props: IStyledProps) => props.mobile ? '6px' : '8px'};
                border: solid ${Colors.white};
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(35deg);
                -ms-transform: rotate(35deg);
                transform: rotate(35deg);

                @media ${deviceMax.tablet} {
                    left: ${(props: IStyledProps) => props.cookie ? '5px' : '10px'};
                    top: ${(props: IStyledProps) => props.cookie ? '2px' : '7px'};
                    width: ${(props: IStyledProps) => props.cookie ? '4px' : '5px'};
                    height: ${(props: IStyledProps) => props.cookie ? '8px' : '10px'};
                }
            }
        }
    }


    > .title {
        display: block;
        font-family: 'Manrope', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        color: ${(props: IStyledProps) => props.titleColor ? Colors.white : Colors.black};
        padding-left: ${(props: IStyledProps) => props.mobile ? '25px' : (props.cookie ? '30px' : '41px')};
        padding-top:${(props: IStyledProps) => props.cookie ? '4px' : '0px'};
        
        @media ${deviceMax.tablet} {
            font-size: ${(props: IStyledProps) => props.cookie ? '10px' : '10px'};
            line-height: ${(props: IStyledProps) => props.cookie ? '16px' : '15px'};
            padding-left: ${(props: IStyledProps) => props.cookie ? '24px' : '41px'};
            padding-top:${(props: IStyledProps) => props.mobile ? '4px' : '0px'};
        }

        > a, span {
            color: ${(props: IStyledProps) => props.titleColor ? Colors.white : Colors.black};
            font-weight: 700;
            text-decoration: none;

            @media ${deviceMax.tablet} {
                font-size: 10px;
                line-height: 15px;
            }
        }
    }
`;

export default SingleCheckboxAndText;
