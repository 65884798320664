import { v4 } from 'uuid';
const packageJson = require('../../package.json');
import { getCookie, setCookie } from 'cookies-next';


export function generateUniqueId(){
    return v4();
}

export function checkIfVercel(host:string){
    return(
        host.split('.')[1] === 'vercel' &&
        host.split('.')[2] === 'app'
    )
}

export function getHostOrigin(){
    if(
        window.location.host === 'localhost:3005' ||
        window.location.host === 'newgen.pembepanjur.com' ||
        window.location.hostname === 'localhost'
    ){
        return 'dev.pembepanjur.com';
    }else if(checkIfVercel(window.location.host)){
        return 'www.pembepanjur.com';
    }else{
        return window.location.host;
    }
}

export function getAppVersion(){
    return process.env.NODE_ENV === 'production' ? process.env.NEXT_BUILD_ID : 'v0.11.11-web-2023.11.11.11.11.11';
}

function getRandomInt(min : number, max : number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
function generateUniqueCookieId() {
    let dateRightNow = new Date();
    let month = ("0" + (dateRightNow.getMonth() + 1)).slice(-2);
    let uniqueCookieId = dateRightNow.getFullYear() + '' + month +  '' + dateRightNow.getDate() + '00' + getRandomInt(1000000,9999999) + '' + '' + getRandomInt(100000,999999);
    uniqueCookieId = uniqueCookieId.toString();
    return uniqueCookieId;
}

export function getUniqueCookieId(){
    const id = getCookie('ppUniqueCookieId');
    if(
        typeof id === 'string' &&
        id !== ''
    ){
        return id;
    }else{
        const newId = generateUniqueCookieId();
        let date = new Date('2030-01-01 01:00:00');
        setCookie('ppUniqueCookieId',newId,{
            expires : date
        });
        return newId;
    }
}
