const Colors = {
    brandColor: '#D2147D',
    brandColorHover: '#f10e8b',
    brandColorDisabled: 'rgb(216,97,159)',
    white: '#FFFFFF',
    black: '#000000',
    blackColor: '#000000',
    pinkColor: '#FFEBF1',
    lightGrayBackground: '#F6F6F6',
    purpleColor: '#752280',
    softPurpleColor: 'rgba(117, 34, 128, 0.7)',
    softBlackColor: 'rgba(0, 0, 0, 0.5)',
    softPinkColor: 'rgba(210, 20, 125, 0.6)',
    ultraSoftPinkColor: 'rgba(210, 20, 125, 0.05)',
    tinySoftPinkColor: 'rgba(210, 20, 125, 0.2)',
    softGrayBackground : '#E5E5E5',
    softGreyColor: 'rgba(0, 0, 0, 0.07)',
    lightBlackColor: 'rgba(0, 0, 0, 0.2)',
    mobileWaveColor: 'rgba(0, 0, 0, 0.05)',
    lightGreyColor: 'rgba(241, 241, 241, 0.5)',
    checkboxTitleColor: '#131726',
    checkboxGreyColor: '#F8F8F8',
    checkboxCheckColor: '#A7DE7B',
    checkboxUncheckColor: 'rgba(241, 241, 241, 0.7)',
    facebookColor: '#3C71C1',
    layoutBackgroundColor: 'rgba(0, 0, 0, 0.04)',
    signInCheckboxColor: '#ccc',
    landingGreyColor: '#737373',
    grayColor: 'rgba(241, 241, 241, 1)',
    borderPurpleColor: '#551E98',
    accountDeleteColor: '#C4C4C4',
    loginPageLineColor: '#00000040',
};

export default Colors;
