import {getHostOrigin} from "../utils/Generators";

export const NavigationURLs = {
    landing : '/',
    login : '/giris',
    register : '/',
    contact : '/iletisim',
    about : '/hakkinda',
    privacy : '/gizlilik-sozlesmesi',
    personal : '/kisisel-veriler',
    membership : '/uyelik-sozlesmesi',
    press : '/basin',
    lightingText : '/aydinlatma-metni',
    support : '/yardim',
    successStorySingle : '/basari-hikaye',
    successStoryMulti : '/basari-hikayeleri',
    successStorySend : '/basari-hikayeleri-gonder',
    emailNotification : '/abonelik-sonlandir',
    forgotPassword : '/sifremi-unuttum',
    career : '/kariyer',
    updatePassword : '/sifre-guncelle',
    complain : '/sikayet-abonelik-sonlandir',
    digitalSalesCareer: '/dijital-satis-kariyer-basvuru',
    generalCareer: '/genel-kariyer-basvuru',
    itInternCareer: '/it-stajyeri-kariyer-basvuru',
    policy: '/cerez-politikasi',
    protectionOfPersonalData: '/kisisel-verilerin-korunmasi',
    callCenterLighting: '/cagri-aydinlatma',
    istanbul: '/istanbul-evlilik-sitesi',
    ankara: '/ankara-evlilik-sitesi',
    izmir: '/izmir-evlilik-sitesi',
    antalya: '/antalya-evlilik-sitesi',
    bursa: '/bursa-evlilik-sitesi',
    blog: 'https://www.pembepanjur.com/blog',
};

export const ExternalNavigationURLs = {
    registrationPage : (phpSessionId:string)=>('//'+getHostOrigin() + '/registration/'),
    homePage : (phpSessionId:string)=>('//'+getHostOrigin() + '/home'),
    homePageWithAccessToken : (accessToken:string)=>('//'+getHostOrigin() + '/home/?access_token='+accessToken),
    homePageWithJWT : (jwt:string)=>('//'+getHostOrigin() + '/home/'),
}

export function NavigateTo( newURL : string ){
    window.location.href = newURL;
}

export function NavigateToRegister(phpSessionId: string) {
    NavigateTo(
        ExternalNavigationURLs.registrationPage(phpSessionId)
    );
}

export function NavigateToHome(phpSessionId: string, accessToken: string = '', jwt: string = ''){
    if(jwt !== ''){
        NavigateTo(
            ExternalNavigationURLs.homePageWithJWT(jwt)
        );
    } else if(accessToken !== ''){
        NavigateTo(
            ExternalNavigationURLs.homePageWithAccessToken(accessToken)
        );
    }else{
        NavigateTo(
            ExternalNavigationURLs.homePage(phpSessionId)
        );
    }
}
