import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax} from "../../../styles/device";

export const CookieCompWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #2b373b;
  color: #fff;
  padding: 20px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;

  &.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  
  > h3.cookie-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    margin: 0 0 10px 0;
    
    @media ${deviceMax.tablet} {
        font-size: 12px;
        line-height: 16px;
    }
  }
  
`;

export const CookieCompContent = styled.div`
  display: flex;
  flex-direction: column;
  
  > div.cookie-content {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    color: #fff;

    > a {
        color: ${Colors.brandColor};
      
        &:hover {
            text-decoration: underline;
        }
    }
    
    @media ${deviceMax.tablet} {
        font-size: 10px;
        line-height: 16px;
    }

    > .button-reject {
      display: inline-block;
      font-size: 15px;
      line-height: 25px;
      font-family: 'Manrope', sans-serif;
      font-weight: 400;
      cursor: pointer;
      padding: 0 0 0 4px;
      letter-spacing: 0.0011em;
      color: ${Colors.brandColor};
      margin: 0;
        transition: all 0.3s ease-in-out;
      
        &:hover {
            text-decoration: underline;
        }
      
        @media ${deviceMax.tablet} {
            font-size: 10px;
            line-height: 16px;
        }
    }
  }

`;

export const CookieCompButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${deviceMax.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CookieCompButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  @media ${deviceMax.tablet} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  &.mobile-direction-row {
    @media ${deviceMax.tablet} {
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: 10px;
    }
  }
`;

export const CookieCompButton = styled.button`
  font-size: 14px;
  line-height: 20px;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  background: ${Colors.brandColor};
  color: ${Colors.white};
  width: fit-content;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: #fff;
    color: #2b373b;
  }

  @media ${deviceMax.tablet} {
    width: 100%;
    margin-right: 0;
    font-size: 10px;
    line-height: 16px;
    padding: 6px 12px;
  }
`;